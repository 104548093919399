class LoadMore {

    /**
     * Get setting.
     * @param parent: container containing items to load (html el)
     * @param btn: show more button (html el)
     * @param visibleMobile: num of visible items on mobile devices (num)
     * @param visibleTablet: num of visible items on tablet devices (num)
     * @param visibleDesktop: num of visible items on desktop devices (num)
     * @param loadMobile: num of items loading on mobile devices (num)
     * @param loadTablet: num of items loading on tablet devices (num)
     * @param loadDesktop: num of items loading on desktop devices (num)
     */
    static init(parent, btn, visibleMobile, visibleTablet, visibleDesktop, loadMobile, loadTablet, loadDesktop) {

        if ( parent && btn ) {
            let children = parent.children; // get items

            let visible = visibleMobile;
            let load = loadMobile;

            if ( window.matchMedia('(min-width: 576px)').matches ) {
                visible = visibleTablet;
                load = loadTablet;
            }

            if ( window.matchMedia('(min-width: 1024px)').matches ) {
                visible = visibleDesktop;
                load = loadDesktop;
            }


            // hide items which should be hidden
            for (let i = visible; i < children.length; i++) {
                children[i].style.display = 'none';
                children[i].classList.add('js-hidden');
            }

            // init load more button
            LoadMore.btn(btn, parent, children, load);
            // hide btn if all items are visible
            LoadMore.hideBtn(btn, parent);
        }
    }

    /**
     * Set show more button.
     * @param el: button (HTML el)
     * @param parent: container containing items to load (html el)
     * @param children: parent direct children
     * @param load: num of items loading (num
     */
    static btn(el, parent, children, load) {

        el.addEventListener('click', function (e) {
            e.preventDefault();
            let delay = 0;
            let helper = 0;

            for (let i = 0; i < children.length; i++) {
                // show hidden item
                if (children[i].classList.contains('js-hidden')) {
                    // setTimeout is necessary to animation effect
                    setTimeout(function () {
                        children[i].style.display = 'block';
                        fadeIn(children[i], 1000);
                        children[i].classList.remove('js-hidden');
                    }, delay);
                    delay += 500;
                    helper++;
                }
                // break loop if showed items = load num
                if (helper === load) break;
            }

            // test if there are any hidden items
            setTimeout(function () {
                LoadMore.hideBtn(el, parent);
            }, delay);
        });
    }

    /**
     * Hide button if there are no more hidden elements.
     * @param el: button (html el)
     * @param parent: container containing items to load (html el)
     */
    static hideBtn(el, parent) {
        let hidden = parent.querySelectorAll('.js-hidden');
        if (hidden.length === 0) el.style.display = 'none';
    }
}

