class Toggle {

    static hide(target) {
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
    };

    static slideUp(target, duration) {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;

        this.hide(target);

        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');

            target.classList.remove('expanded');
            target.classList.add('hidden');
        }, duration);
    };

    static slideDown(target, duration) {
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;

        if (display === 'none')
            display = 'block';

        target.style.display = display;

        let height = target.offsetHeight;

        this.hide(target);

        target.offsetHeight;
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';

        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');

        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');

            target.classList.remove('hidden');
            target.classList.add('expanded');
        }, duration);
    };

    static formToggle() {
        let target = document.getElementById("formTarget"),
            closer = document.getElementById("formClose"),
            opener = document.getElementById("formOpen");

        if (closer && target) {
            closer.addEventListener('click', function () {
                Toggle.slideUp(target, 400);
                target.nextElementSibling.style.display = 'inline-block';
            });
        }

        if (opener && target) {
            opener.addEventListener('click', function () {
                Toggle.slideDown(target, 400);
                target.nextElementSibling.style.display = 'none';
            });
        }
    }
}
