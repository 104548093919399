
document.addEventListener('DOMContentLoaded', (event) => {
    Menu.setScrolling();
    Menu.listenOpen();
    Menu.closeMenu();
    FormValidation.vivalid();
    PhotoSwipePlugin.init();
    PhotoSwipePlugin.presenter();
    Recaptcha.init(grecaptcha);
    //PikadayPlugin.init();
    objectFitImages();
    Toggle.formToggle();
    LoadMore.init(
        document.getElementById('js-load-more-services'),
        document.getElementById('js-load-more-services-btn'),
        3,
        6,
        9,
        3,
        2,
        3
    );
    LoadMore.init(
        document.getElementById('js-load-more-gallery'),
        document.getElementById('js-load-more-gallery-btn'),
        6,
        6,
        6,
        2,
        2,
        3
    );

    const formTo = flatpickr('#formTo', {
        "locale": "cs",
        "dateFormat": "j.m.Y, H:i",
        //"altInput": true,
        "enableTime": true
    });

    flatpickr('#formSince', {
        "locale": "cs",
        "dateFormat": "j.m.Y, H:i",
        //"altInput": true,
        "enableTime": true,
        onChange: function(selectedDates, dateStr, instance) {
            formTo.setDate(selectedDates[0]);
        },
    });

    MicroModal.init({
        disableScroll: true,
        awaitCloseAnimation: true
    });


    if (window.location.hash === '#prace') {
        MicroModal.show('modal-job');
    }
});
