class PikadayPlugin {

    static init() {

        const pickerSince = document.getElementById('formSince');
        const pickerTo = document.getElementById('formTo');

        if (pickerSince && pickerTo) {

            let pikadaySince = new Pikaday({
                field: pickerSince,
                format: 'D.M.YYYY',
                toString(date, format) {
                    // you should do formatting based on the passed format,
                    // but we will just return 'D/M/YYYY' for simplicity
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    return `${day}.${month}.${year}`;
                },
                onSelect: function (date) {
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    this.value = `${day}.${month}.${year}`;
                    vivalid.htmlInterface.initAll();
                },
                firstDay: 1,
                i18n: {
                    previousMonth: 'Předchozí měsíc',
                    nextMonth: 'Následující měsíc',
                    months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
                    weekdays: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
                    weekdaysShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So']
                }
            });

            let pikadayTo = new Pikaday({
                field: pickerTo,
                format: 'D.M.YYYY',
                toString(date, format) {
                    // you should do formatting based on the passed format,
                    // but we will just return 'D/M/YYYY' for simplicity
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    return `${day}.${month}.${year}`;
                },
                onSelect: function (date) {
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    this.value = `${day}.${month}.${year}`;
                    vivalid.htmlInterface.initAll();
                },
                firstDay: 1,
                i18n: {
                    previousMonth: 'Předchozí měsíc',
                    nextMonth: 'Následující měsíc',
                    months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
                    weekdays: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
                    weekdaysShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So']
                }
            });
        }
    }
}
