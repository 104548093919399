var RecaptchaData = {
    resolved: false,
    init: false
};

class Recaptcha {

    static init() {
        var items = document.getElementsByClassName('g-recaptcha');
        var length = items.length;

        if (length === 0) {
            return;
        }

        grecaptcha.ready(function () {
            RecaptchaData.resolved = false;
            var form;

            for (var i = 0; i < length; i++) {
                grecaptcha.render(items[i]);

                form = items[i].closest('form');
                form.addEventListener('submit', Recaptcha.submitListenerFactory(form));
            }
        });
        RecaptchaData.init = true;
    }

    /**
     * @param {Element} form
     * @return Event
     */
    static submitListenerFactory(form) {
        return function (e) {
            // we already have reCaptcha response, or the form is invalid - or submission is prevented for some other, unknown reason
            if (RecaptchaData.resolved) {
                return;
            }

            e.preventDefault();

            Recaptcha.processSubmit(form);
        };
    }

    static processSubmit(form) {
        grecaptcha.execute().then(function (token) {
            RecaptchaData.resolved = true;

            // reCaptcha token expires after 2 minutes; make it 5 seconds earlier just in case network is slow
            setTimeout(function () {
                resolved = false;
            }, (2 * 60 - 5) * 1000);

            var inputs = document.getElementsByClassName('g-recaptcha-response');
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].value = token;
            }

            form.submit();
        });
    }

    /**
     * @param {Element} form
     * @return boolean
     */
    static hasFormRecaptcha(form)
    {
        if(form.querySelectorAll('.g-recaptcha-response').length > 0) {
            return true;
        } else {
            return false;
        }
    }
}